@import '../../variables';

.table-container {
  overflow-x: auto;
}

table {
  border: 0;
  border-collapse: collapse;
  border-spacing: 0;

  thead,
  tbody {
    border: 0;
  }

  thead {
    background: $n200;
    border-bottom: 2px solid $n400;

    th {
      font-weight: 600;
    }
  }

  tbody {
    tr {
      border: 0;
      border-bottom: 1px solid $n300;
    }
  }

  .button-group {
    margin-bottom: 0;
  }
}


tr {
  &.highlight {
    // As styled by foundation's callout
    background-color: $o100;
  }

  &.alert {
    background-color: $r100;
  }
}

th,
td {
  &.fit {
    white-space: nowrap;
    width: 1%;
  }

  &.collapse-all {
    word-break: break-all;
    word-wrap: break-word;
  }
}

td {
  .button {
    margin: auto;
  }

  &.contains-inputs {
    input,
    select {
      margin: 0;
    }

    .input-group {
      margin: 0;
    }
  }
}

.table-full-width {
  margin-left: auto;
  margin-right: auto;
  max-width: initial;
  width: 100%;

  tr td.fit {
    white-space: nowrap;
    width: 1%;

    .button-group {
      .button {
        float: none;
      }
    }
  }
}
