@import '~foundation-sites/scss/foundation';
@import '../../variables';

// Overrides and styles for form inputs
// Should hopefully be removed as all styles are moved to individual components

input,
select,
.Select-control {
  &:hover {
    background-color: $n150;
  }
}

select {
  &:hover {
    &:focus {
      background-color: $n150;
    }
  }
}

input,
textarea,
select {
  &:-ms-input-placeholder { // sass-lint:disable-line no-vendor-prefixes
    color: $n400;
  }

  &:disabled{
    &,
    &:hover {
      cursor: not-allowed;
      background-color: $n200;
    }
  }
}

input.is-invalid-input:focus {
  border-color: $r300;
}

.input-group {
  > :last-child {
    border-left: 0;
    border-radius: 0 $input-radius $input-radius 0;
  }
}

.input-group-label {
  &.disabled {
    .icon {
      color: $n400;
    }
  }
}

.form-error {
  font-weight: 600;
}

.select2 {
  margin: 0 0 1rem;

  .select2-selection {
    border: 1px solid $n400;
    height: 2.4375rem;

    &:focus {
      outline: none;
      border: 1px solid $i400;
      transition: border-color 0.3s ease-in-out, background-color 0.3s ease;
    }

    &:hover {
      background-color: $n200;
    }
  }

  .selection {
    .select2-selection__rendered {
      line-height: 1.5;
      padding: 8px 24px 8px 8px;
      color: $n700;
    }

    .select2-selection__arrow {
      height: 24px;
      margin-top: 7px;
      margin-right: 2px;

      b {
        border-color: $n700 transparent transparent transparent;
      }
    }
  }
}

.select2-dropdown {
  .select2-results__option {
    color: $n600;

    &.select2-results__option[aria-selected=true] {
      background-color: $n200;
      color: $n700;
    }

    &.select2-results__option--highlighted[aria-selected] {
      background-color: $i100;
      color: $n700;
    }
  }
}
