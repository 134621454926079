@import '../../../common/sass/variables';

$toast-styles: (
  primary:    (background-color: $i100, text-color: $n800, border-color: $i400),
  secondary:  (background-color: $b100, text-color: $n800, border-color: $b400),
  success:    (background-color: $g100, text-color: $n800, border-color: $g400),
  warning:    (background-color: $o100, text-color: $n800, border-color: $o400),
  alert:      (background-color: $r100, text-color: $n800, border-color: $r300),
  neutral:    (background-color: $n200, text-color: $n800, border-color: $n400),
  info:       (background-color: $c100, text-color: $n800, border-color: $c400)
);

@mixin toast-color-coding ($color-scheme) {
  background-color: map-get($color-scheme, background-color);
  border-color: map-get($color-scheme, border-color);

  .icon {
    color: map-get($color-scheme, border-color);
  }

  .toast-content {
    color: map-get($color-scheme, text-color);
  }
}

:local(.ToastClass) {
  align-items: flex-start;
  border: .1rem solid;
  border-radius: $border-radius;
  box-shadow: $box-shadow-lv4-front-of-mind;
  display: flex;
  flex-direction: row;
  height: 100%;
  margin-bottom: 10px;
  overflow: hidden;
  padding: .75rem;

  >.icon,
  .fa-container {
    flex: 0 auto;

    &.toast-icon {
      font-size: 2rem;
    }

    &.toast-close-span {
      cursor: pointer;
      font-size: .95rem;
      position: relative;
      top: -.3rem;
    }
  }


  >.toast-content {
    flex: auto;
    margin-top: $spacing-xsmall;
    padding: 0 .575rem;
    position: relative;
    top: -.375rem;
    word-break: break-word;

    &.no-heading {
      margin-top: $spacing-small;
    }
  }


  @each $colour, $values in $toast-styles {
    &.#{$colour} {
      @include toast-color-coding($values);
    }
  }
}
