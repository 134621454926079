@import '../../variables';

h1, .h1 {
  font-size: 2rem;
  font-weight: 600;
  line-height: 3.5rem;
}

h6 {
  letter-spacing: 1px;
  text-transform: uppercase;
}

.display-text-1 {
  font-size: 4.125rem;
  font-weight: 300;
}

.display-text-2 {
  font-size: 3.25rem;
  font-weight: 400;
}

.display-text-3 {
  font-size: 2.5625em;
  font-weight: 400;
}

.subheader {
  color: $n600;
}

.title {
  color: $n600;
  font-size: 1.4375rem;
  margin-bottom: .4rem;
}

%section-header {
  color: $n800;
  font-size: 1.125em;
  font-weight: 600;
}

.section-header {
  @extend %section-header
}

p.page-subheader {
  color: $n600;
  font-size: .9375rem;
}
