@import '../../../common/sass/variables';

:local(.SmartList) {
  .list-title {
    color: $n800;
    font-size: 1.125em;
    font-weight: 600;
    margin: 16px 0;
  }

  .header-row {
    background-color: $n200;
    color: $n700;
    padding: .75rem 10px;
  }

  .cursor-auto {
    cursor: auto;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .vertical-align-center {
    margin-top: .6%;
  }

  .show-all-row {
    color: $n700;
    font-weight: 600;
    padding-top: 10px;
    text-align: center;
    text-transform: uppercase;
  }

  .hide-row {
    display: none;
  }

  &.hover-bg {
    .item-row {
      &:hover {
        background-color: $n150;
      }
    }
  }

  .item-row {
    align-items: center;
    border-bottom: 1px solid $n300;
    color: $n700;
    min-height: 80px;
    padding: 10px;
    position: relative;
    top: 50%;
    transition: background-color .15s ease-in;

    .dd-menu {
      border-radius: $border-radius;
      transition: background-color .2s ease-in;

      button {
        &.option {
          padding: 10px 5px;
        }
      }

      &:hover {
        background-color: $n200;
      }

      .fa-ellipsis-v {
        font-size: 24px;
      }

      a {
        &:hover {
          background-color: $n200;
        }
      }
    }

    &.alert {
      background-color: $r100;

      &:hover {
        background-color: $r200;
      }
    }
  }

  .callout {
    background-color: $n100;
    border: 0;
    color: $n600;
  }

  .spinner {
    padding: 2rem 0;
  }

  .sticky {
    border-bottom: 1px solid $n300;
    position: sticky;
    top: 0;
    z-index: 1;
  }
}
