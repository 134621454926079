@import '../../../common/sass/variables';

$typography-styles: (
  xsmall: (
    font-size: $fz-xsmall,
    line-height: $lh-xsmall,
    letter-spacing: .02em
  ),
  small: (
    font-size: $fz-small,
    line-height: $lh-small,
    letter-spacing: normal
  ),
  body: (
    font-size: $fz-body,
    line-height: $lh-body,
    letter-spacing: normal
  ),
  heading: (
    font-size: $fz-heading,
    line-height: $lh-heading,
    letter-spacing: .02em
  ),
  display: (
    font-size: $fz-display,
    line-height: $lh-display,
    letter-spacing: .02em
  ),
  display-large: (
    font-size: $fz-display-large,
    line-height: $lh-display-large,
    letter-spacing: .02em
  )
);

@mixin add-typography-styles ($style) {
  font-size: map-get($style, font-size);
  letter-spacing: map-get($style, letter-spacing);
  line-height: map-get($style, line-height);
}

:local(.ihrEmoji) {
  @each $typography-size, $style in $typography-styles {
    &.#{$typography-size} {
      @include add-typography-styles($style);
    }
  }

  .emoji-mart-emoji {
    display: inline-block;
    line-height: 14px;
    margin-top: -.13em;
    vertical-align: middle;
  }
}
