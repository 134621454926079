@import '../../../common/sass/variables';

$button-styles: (
  // ––––––––––––––––––––––––––––––––––––
  // Functional
  // ––––––––––––––––––––––––––––––––––––
  primary: (
    background-color: $i400,
    border: transparent,
    text-color: $n100,
    disabled-background-color: $n200,
    disabled-text-color: $n400,
    hover-background-color: $i500,
    hover-text-color: $n100,
    active-background-color: $i600,
    active-text-color: $n100,
    focus-background-color: $i100,
    focus-text-color: $i400,
    focus-border-color: $i400
  ),
  secondary: (
    background-color: $b400,
    border: transparent,
    text-color: $n100,
    disabled-background-color: $n200,
    disabled-text-color: $n400,
    hover-background-color: $b500,
    hover-text-color: $n100,
    active-background-color: $b600,
    active-text-color: $n100,
    focus-background-color: $b100,
    focus-text-color: $b400,
    focus-border-color: $b400
  ),
  success: (
    background-color: $g200,
    border: transparent,
    text-color: $g600,
    disabled-background-color: $n200,
    disabled-text-color: $n400,
    hover-background-color: $g300,
    hover-text-color: $g600,
    active-background-color: $g400,
    active-text-color: $n100,
    focus-background-color: $g100,
    focus-text-color: $g600,
    focus-border-color: $g400
  ),
  warning: (
    background-color: $o200,
    border: transparent,
    text-color: $o600,
    disabled-background-color: $n200,
    disabled-text-color: $n400,
    hover-background-color: $o300,
    hover-text-color: $o600,
    active-background-color: $o400,
    active-text-color: $n100,
    focus-background-color: $o100,
    focus-text-color: $o600,
    focus-border-color: $o400
  ),
  alert: (
    background-color: $r100,
    border: transparent,
    text-color: $r500,
    disabled-background-color: $n200,
    disabled-text-color: $n400,
    hover-background-color: $r200,
    hover-text-color: $r500,
    active-background-color: $r400,
    active-text-color: $n100,
    focus-background-color: $r100,
    focus-text-color: $r500,
    focus-border-color: $r300
  ),
  neutral: (
    background-color: $n300,
    border: transparent,
    text-color: $n700,
    disabled-background-color: $n200,
    disabled-text-color: $n400,
    hover-background-color: $n400,
    hover-text-color: $n700,
    active-background-color: $n600,
    active-text-color: $n100,
    focus-background-color: $n400,
    focus-text-color: $n700,
    focus-border-color: $n600
  ),
  highlight: (
    background-color: $c200,
    border: transparent,
    text-color: $c600,
    disabled-background-color: $n200,
    disabled-text-color: $n400,
    hover-background-color: $c300,
    hover-text-color: $c600,
    active-background-color: $c400,
    active-text-color: $n100,
    focus-background-color: $c100,
    focus-text-color: $c600,
    focus-border-color: $c400
  ),
  dark: (
    background-color: $n800,
    border: transparent,
    text-color: $n100,
    disabled-background-color: $n200,
    disabled-text-color: $n400,
    hover-background-color: $n600,
    hover-text-color: $n200,
    active-background-color: $n500,
    active-text-color: $n100,
    focus-background-color: $n300,
    focus-text-color: $n800,
    focus-border-color: $n500
  ),
  light: (
    background-color: $n100,
    border: transparent,
    text-color: $n700,
    disabled-background-color: $n200,
    disabled-text-color: $n400,
    hover-background-color: $n200,
    hover-text-color: $n800,
    active-background-color: $n300,
    active-text-color: $n100,
    focus-background-color: $n200,
    focus-text-color: $n800,
    focus-border-color: $n400
  ),
  // ––––––––––––––––––––––––––––––––––––
  // Borderless
  // ––––––––––––––––––––––––––––––––––––
  primary-borderless: (
    background-color: transparent,
    border: transparent,
    text-color: $i400,
    disabled-background-color: $n200,
    disabled-text-color: $n400,
    hover-background-color: $i100,
    hover-text-color: $i600,
    active-background-color: $i200,
    active-text-color: $i600,
    focus-background-color: $i100,
    focus-text-color: $i600,
    focus-border-color: $i400
  ),
  secondary-borderless: (
    background-color: transparent,
    border: transparent,
    text-color: $b400,
    disabled-background-color: $n200,
    disabled-text-color: $n400,
    hover-background-color: $b100,
    hover-text-color: $b600,
    active-background-color: $b200,
    active-text-color: $b600,
    focus-background-color: $b100,
    focus-text-color: $b600,
    focus-border-color: $b400
  ),
  success-borderless: (
    background-color: transparent,
    border: transparent,
    text-color: $g600,
    disabled-background-color: $n200,
    disabled-text-color: $n400,
    hover-background-color: $g100,
    hover-text-color: $g600,
    active-background-color: $g200,
    active-text-color: $g600,
    focus-background-color: $g100,
    focus-text-color: $g600,
    focus-border-color: $g400
  ),
  warning-borderless: (
    background-color: transparent,
    border: transparent,
    text-color: $o600,
    disabled-background-color: $n200,
    disabled-text-color: $n400,
    hover-background-color: $o100,
    hover-text-color: $o600,
    active-background-color: $o200,
    active-text-color: $o600,
    focus-background-color: $o100,
    focus-text-color: $o600,
    focus-border-color: $o400
  ),
  alert-borderless: (
    background-color: transparent,
    border: transparent,
    text-color: $r600,
    disabled-background-color: $n200,
    disabled-text-color: $n400,
    hover-background-color: $r100,
    hover-text-color: $r600,
    active-background-color: $r200,
    active-text-color: $r600,
    focus-background-color: $r100,
    focus-text-color: $r600,
    focus-border-color: $r400
  ),
  neutral-borderless: (
    background-color: transparent,
    border: transparent,
    text-color: $n700,
    disabled-background-color: $n200,
    disabled-text-color: $n400,
    hover-background-color: $n200,
    hover-text-color: $n700,
    active-background-color: $n300,
    active-text-color: $n700,
    focus-background-color: $n200,
    focus-text-color: $n700,
    focus-border-color: $n400
  ),
  highlight-borderless: (
    background-color: transparent,
    border: transparent,
    text-color: $c600,
    disabled-background-color: $n200,
    disabled-text-color: $n400,
    hover-background-color: $c100,
    hover-text-color: $c600,
    active-background-color: $c200,
    active-text-color: $c600,
    focus-background-color: $c100,
    focus-text-color: $c600,
    focus-border-color: $c400
  ),
  dark-borderless: (
    background-color: transparent,
    border: transparent,
    text-color: $n700,
    disabled-background-color: $n200,
    disabled-text-color: $n400,
    hover-background-color: $n300,
    hover-text-color: $n800,
    active-background-color: $n400,
    active-text-color: $n800,
    focus-background-color: $n300,
    focus-text-color: $n800,
    focus-border-color: $n500
  ),
  light-borderless: (
    background-color: transparent,
    border: transparent,
    text-color: $n700,
    disabled-background-color: $n200,
    disabled-text-color: $n400,
    hover-background-color: $n200,
    hover-text-color: $n800,
    active-background-color: $n300,
    active-text-color: $n800,
    focus-background-color: $n200,
    focus-text-color: $n800,
    focus-border-color: $n400
  ),
  // ––––––––––––––––––––––––––––––––––––
  // Hollow
  // ––––––––––––––––––––––––––––––––––––
  primary-hollow: (
    background-color: transparent,
    border: $i400,
    text-color: $i400,
    disabled-background-color: $n200,
    disabled-text-color: $n400,
    disabled-border-color: transparent,
    hover-background-color: $i100,
    hover-text-color: $i400,
    hover-border-color: $i400,
    active-background-color: $i200,
    active-text-color: $i600,
    focus-background-color: $i100,
    focus-text-color: $i600,
    focus-border-color: $i400
  ),
  secondary-hollow: (
    background-color: transparent,
    border: $b400,
    text-color: $b400,
    disabled-background-color: $n200,
    disabled-text-color: $n400,
    disabled-border-color: transparent,
    hover-background-color: $b100,
    hover-text-color: $b400,
    hover-border-color: $b400,
    active-background-color: $b200,
    active-text-color: $b600,
    focus-background-color: $b100,
    focus-text-color: $b600,
    focus-border-color: $b400
  ),
  success-hollow: (
    background-color: transparent,
    border: $g400,
    text-color: $g600,
    disabled-background-color: $n200,
    disabled-text-color: $n400,
    disabled-border-color: transparent,
    hover-background-color: $g100,
    hover-text-color: $g600,
    hover-border-color: $g400,
    active-background-color: $g200,
    active-text-color: $g600,
    focus-background-color: $g100,
    focus-text-color: $g600,
    focus-border-color: $g400
  ),
  warning-hollow: (
    background-color: transparent,
    border: $o400,
    text-color: $o600,
    disabled-background-color: $n200,
    disabled-text-color: $n400,
    disabled-border-color: transparent,
    hover-background-color: $o100,
    hover-text-color: $o600,
    hover-border-color: $o400,
    active-background-color: $o200,
    active-text-color: $o600,
    focus-background-color: $o100,
    focus-text-color: $o600,
    focus-border-color: $o400
  ),
  alert-hollow: (
    background-color: transparent,
    border: $r400,
    text-color: $r600,
    disabled-background-color: $n200,
    disabled-text-color: $n400,
    disabled-border-color: transparent,
    hover-background-color: $r100,
    hover-text-color: $r600,
    hover-border-color: $r400,
    active-background-color: $r200,
    active-text-color: $r600,
    focus-background-color: $r100,
    focus-text-color: $r600,
    focus-border-color: $r400
  ),
  neutral-hollow: (
    background-color: transparent,
    border: $n400,
    text-color: $n700,
    disabled-background-color: $n200,
    disabled-text-color: $n400,
    disabled-border-color: transparent,
    hover-background-color: $n150,
    hover-text-color: $n700,
    hover-border-color: $n500,
    active-background-color: $n300,
    active-text-color: $n700,
    focus-background-color: $n200,
    focus-text-color: $n700,
    focus-border-color: $n400
  ),
  highlight-hollow: (
    background-color: transparent,
    border: $c400,
    text-color: $c600,
    disabled-background-color: $n200,
    disabled-text-color: $n400,
    disabled-border-color: transparent,
    hover-background-color: $c100,
    hover-text-color: $c600,
    hover-border-color: $c400,
    active-background-color: $c200,
    active-text-color: $c600,
    focus-background-color: $c100,
    focus-text-color: $c600,
    focus-border-color: $c400
  ),
  dark-hollow: (
    background-color: transparent,
    border: $n500,
    text-color: $n700,
    disabled-background-color: $n200,
    disabled-text-color: $n400,
    disabled-border-color: transparent,
    hover-background-color: $n300,
    hover-text-color: $n800,
    active-background-color: $n400,
    active-text-color: $n800,
    focus-background-color: $n300,
    focus-text-color: $n800,
    focus-border-color: $n500
  ),
  light-hollow: (
    background-color: transparent,
    border: $n400,
    text-color: $n700,
    disabled-background-color: $n200,
    disabled-text-color: $n400,
    disabled-border-color: transparent,
    hover-background-color: $n200,
    hover-text-color: $n800,
    hover-border-color: $n500,
    active-background-color: $n300,
    active-text-color: $n800,
    focus-background-color: $n200,
    focus-text-color: $n800,
    focus-border-color: $n400
  ),
  // ––––––––––––––––––––––––––––––––––––
  // Special Case
  // ––––––––––––––––––––––––––––––––––––
  cancel: (
    background-color: transparent,
    border: transparent,
    text-color: $n700,
    disabled-background-color: $n200,
    disabled-text-color: $n400,
    hover-background-color: $n300,
    hover-text-color: $n800,
    active-background-color: $n300,
    active-text-color: $n800,
    focus-background-color: $n300,
    focus-text-color: $n800,
    focus-border-color: $n400
  ),
  back: (
    background-color: transparent,
    border: transparent,
    text-color: $n600,
    disabled-background-color: transparent,
    disabled-text-color: $n400,
    hover-background-color: transparent,
    hover-text-color: $i400,
    active-background-color: transparent,
    active-text-color: $i400,
    focus-background-color: transparent,
    focus-text-color: $i400,
    focus-border-color: transparent
  ),
  add: (
    background-color: transparent,
    border: transparent,
    text-color: $i400,
    disabled-background-color: transparent,
    disabled-text-color: $n400,
    hover-background-color: transparent,
    hover-text-color: $i500,
    active-background-color: transparent,
    active-text-color: $i500,
    focus-background-color: transparent,
    focus-text-color: $i500,
    focus-border-color: transparent
  ),
  add-subtle: (
    background-color: transparent,
    border: transparent,
    text-color: $n700,
    disabled-background-color: $n200,
    disabled-text-color: $n400,
    hover-background-color: $i100,
    hover-text-color: $i600,
    active-background-color: $i200,
    active-text-color: $i600,
    focus-background-color: $i100,
    focus-text-color: $i600,
    focus-border-color: $i400
  ),
  delete: (
    background-color: $r100,
    border: transparent,
    text-color: $r500,
    disabled-background-color: $n200,
    disabled-text-color: $n400,
    hover-background-color: $r200,
    hover-text-color: $r500,
    active-background-color: $r400,
    active-text-color: $n100,
    focus-background-color: $r100,
    focus-text-color: $r500,
    focus-border-color: $r300
  ),
  delete-subtle: (
    background-color: $n300,
    border: transparent,
    text-color: $n700,
    disabled-background-color: $n200,
    disabled-text-color: $n400,
    hover-background-color: $r200,
    hover-text-color: $r500,
    active-background-color: $r400,
    active-text-color: $n100,
    focus-background-color: $r100,
    focus-text-color: $r500,
    focus-border-color: $r300
  ),
  resolve: (
    background-color: $n300,
    border: transparent,
    text-color: $n700,
    disabled-background-color: $n200,
    disabled-text-color: $n400,
    hover-background-color: $g300,
    hover-text-color: $g600,
    active-background-color: $g400,
    active-text-color: $n100,
    focus-background-color: $g100,
    focus-text-color: $g600,
    focus-border-color: $g400
  ),
  menu-action: (
    background-color: transparent,
    border: transparent,
    text-color: $n700,
    disabled-background-color: $n200,
    disabled-text-color: $n400,
    hover-background-color: transparent,
    hover-text-color: $i400,
    active-background-color: transparent,
    active-text-color: $i400,
    focus-background-color: transparent,
    focus-text-color: $i400,
    focus-border-color: $i400
  )
);

@mixin add-disabled ($background-color, $text-color, $border-color) {
  &:disabled,
  &.disabled,
  &[disabled] {
    cursor: not-allowed;
  }

  &:not(.show-spinner) {
    &:disabled,
    &.disabled,
    &[disabled] {
      background-color: $background-color;
      border-color: $border-color;
      color: $text-color;
      cursor: not-allowed;
      opacity: 1;

      &:focus,
      &:hover {
        background-color: $background-color;
        border-color: $border-color;
        color: $text-color;
      }
    }
  }
}

@mixin add-button-style ($style) {
  @include add-disabled(
    map-get($style, disabled-background-color),
    map-get($style, disabled-text-color),
    map-get($style, disabled-border-color)
  );

  background-color: map-get($style, background-color);
  border-color: map-get($style, border);
  color: map-get($style, text-color);

  &:hover,
  &.hover {
    background-color: map-get($style, hover-background-color);
    color: map-get($style, hover-text-color);

    @if map-has-key($style, hover-border-color) {
      border-color: map-get($style, hover-border-color);
    }
  }

  &:focus {
    background-color: map-get($style, focus-background-color);
    color: map-get($style, focus-text-color);

    @if map-has-key($style, focus-border-color) {
      border-color: map-get($style, focus-border-color);
    }
  }

  &:active,
  &.active {
    background-color: map-get($style, active-background-color);
    color: map-get($style, active-text-color);
  }
}


%button {
  border: 1px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1;
  outline: none;
  padding: 12px 14px;
  position: relative;
  text-align: center;
  transition: background-color .25s ease-out, color .25s ease-out;

  &.back {
    padding-left: 0;
  }

  &.add {
    padding-left: 0;
    padding-right: 0;
  }

  .icon {
    vertical-align: initial;
  }

  @each $colour, $style in $button-styles {
    &.#{$colour} {
      @include add-button-style($style);
    }
  }

  &.full-width {
    width: 100%;
  }

  &.legacy-margins {
    margin: 0 .125rem 1rem;
  }

  &.small {
    font-size: .75rem;
    padding: 10px 12px;

    .button-icon {
      > .icon {
        font-size: .75rem;
      }
    }
  }

  &.large {
    font-size: 1.25rem;
    padding: 17px 20px;

    .button-icon {
      > .icon {
        font-size: 1.25rem;
      }
    }
  }

  .button-icon {
    >.icon {
      font-size: .875rem;
    }

    &.left {
      margin-right: $spacing-xsmall;
    }

    &.right {
      margin-left: $spacing-xsmall;
    }

    &.center {
      margin: 0;
    }
  }

  &.show-spinner > :last-child {
    color: transparent;
  }

  .spinner {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
  }
}

:local(.button) {
  @extend %button;
}

// --------------
// Legacy global button class for non-javascript support
// --------------
.button {
  @extend %button;

  // Extend neutral by default
  @include add-button-style(
    map-get($button-styles, neutral)
  );

  // Legacy buttons always have margin
  margin: 0 .125rem 1rem;

  // Only used on legacy pages for dropdowns
  &.holster {
    @include add-button-style(
      map-get($button-styles, neutral-borderless)
    );

    margin: 0;
    padding: 7px 6px;

    .icon {
      font-size: 1.5rem;
      line-height: 1;
      margin: 0;
      width: 1.3em;
    }
  }

  // Legacy button for removing rows of data
  &.remove {
    @include add-button-style(
      map-get($button-styles, delete-subtle)
    );

    border-radius: 50%;
    height: 1.55rem;
    line-height: 1.55rem;
    margin: .5rem;
    padding: 0;
    width: 1.55rem;

    > .icon {
      margin: 0;
    }
  }
}
