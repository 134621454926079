@import '../../../common/sass/variables';
@import '~react-dates/lib/css/_datepicker.css';

:local(.singleDatePickerOverrides) {
  .MonthPicker_input {
    margin: $spacing-medium 0 0;
  }

  .CalendarMonth_table {
    tr {
      border-bottom: 0;
    }
  }

  .SingleDatePicker_picker {
    // NOTE: the order of these styles DO matter
    .CalendarMonth_caption {
      > strong {
        color: $n800;
        font-size: $fz-heading;
        font-weight: 600;
      }
    }

    .CalendarDay {
      &:hover {
        background: $n250;
      }
    }

    .CalendarDay__today {
      background-color: $n250;
      color: $n800;
      font-weight: $fw-semi-bold;

      .CalendarDay__blocked_out_of_range {
        background-color: $n200;
        color: $n600;
      }
    }

    .CalendarDay__blocked_out_of_range {
      cursor: not-allowed;
    }

    // Will edit everything selected including everything between a range of dates
    .CalendarDay__selected_span {
      background: $i400; //background
      border: 1px solid $i400; //text
      color: $n100; //default styles include a border
    }

    // Will edit selected date or the endpoints of a range of dates
    .CalendarDay__selected {
      background: $i400;
      color: $n100;

      // Will edit when hovered over. _span style also has this property
      &:hover {
        background: $i400;
        color: $n100;
      }
    }

    .DayPickerNavigation_button__horizontalDefault {
      padding: 9px;
    }
  }

  .SingleDatePickerInput__showClearDate {
    padding-right: 0;
  }

  .SingleDatePickerInput_clearDate {
    color: $n700;
    font-size: $fz-heading;
    line-height: $lh-heading;
    outline: none;
    position: absolute;
    top: 19.5px;
    transition: color .3s ease-in-out;

    &:focus,
    &:hover {
      color: $r600;
    }

    &:disabled {
      color: $n600;
      cursor: not-allowed;
    }
  }

  .SingleDatePickerInput__disabled {
    background: none;
  }

  .DateInput__disabled {
    background: none;
  }

  .DateInput_fang {
    top: 56px !important; // sass-lint:disable-line no-important
  }

  .DateInput_input {
    border: 1px solid $n400;
    border-radius: $border-radius;
    color: $n700;
    font-size: $fz-body;
    font-weight: $fw-normal;
    line-height: 1.5;
    margin-bottom: 0;
    padding: .5rem;
    transition: border-color .3s ease-in-out, background-color .3s ease;

    &:hover {
      background-color: $n150;
    }
  }

  .DateInput_input__disabled {
    color: $n600;

    &:hover {
      background-color: $n200;
    }
  }

  &.is-invalid-input {
    &:not(:focus) {
      background-color: transparent;
    }

    input {
      border-color: $r400;
      margin: 0;

      &:not(:focus) {
        background-color: mix($r400, #fff, 10%);

        &::placeholder {
          color: $r400;
        }

        &:-ms-input-placeholder {  // sass-lint:disable-line no-vendor-prefixes
          color: $r400;
        }
      }
    }
  }

  &.input-group-left {
    input {
      border-radius: 4px 0 0 4px;
    }
  }

  &.input-group-middle {
    input {
      border-radius: 0;
    }
  }

  &.input-group-right {
    input {
      border-radius: 0 4px 4px 0;
    }
  }
}
