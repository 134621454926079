@import '../../common/sass/variables';

:local(.input) {
  &:disabled,
  &[disabled] {
    color: $n600;
  }

  &.input-group-left {
    border-radius: 4px 0 0 4px;
  }

  &.input-group-middle {
    border-radius: 0;
  }

  &.input-group-right {
    border-radius: 0 4px 4px 0;
  }
}
