@import '../../../common/sass/variables';

@mixin page-top {
  :local(.top),
  .ihr-layout__top {
    flex-wrap: nowrap;
    padding-bottom: .75rem;
    padding-top: .75rem;
    width: 100%;
  }
}

@mixin base-layout {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

@mixin profile-layout {
  align-items: center;

  @media only screen and (min-width: $breakpoint-desktop) {
    padding: 0 24px;

    :local(.header),
    .ihr-layout__header {
      border-radius: $border-radius;
    }

    :local(.content),
    .ihr-layout__content {
      border-radius: $border-radius;
      margin-top: -41px;
      max-width:  $breakpoint-big-desktop / 12 * 10;
      width: 100% / 12 * 10;
    }
  }

  @media only screen and (max-width: $breakpoint-desktop - 1) and (min-width: $breakpoint-tablet)  {
    padding: 0 24px;

    :local(.header),
    .ihr-layout__header {
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
      margin-top: 24px;
    }

    :local(.content),
    .ihr-layout__content {
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
      width: 100%;
    }
  }

  @media only screen and (max-width: $breakpoint-tablet - 1) {
    :local(.top),
    .ihr-layout__top {
      padding-left: 24px;
      padding-right: 24px;
    }

    :local(.content),
    .ihr-layout__content {
      width: 100%;
    }
  }

  :local(.header),
  .ihr-layout__header {
    margin-left: auto;
    margin-right: auto;
    max-width: $breakpoint-big-desktop;
    overflow: hidden;
    width: 100%;
  }

  :local(.content),
  .ihr-layout__content {
    margin-left: auto;
    margin-right: auto;
  }
}

@mixin index-layout {
  align-items: center;

  @media only screen and (min-width: $breakpoint-desktop) {
    padding: 0 24px;

    :local(.content),
    .ihr-layout__content {
      max-width:  $breakpoint-big-desktop / 12 * 10;
      width: 100% / 12 * 10;
    }
  }

  @media only screen and (max-width: $breakpoint-desktop - 1) and (min-width: $breakpoint-tablet)  {
    padding: 0 24px;

    :local(.header),
    .ihr-layout__header {
      margin-top: 24px;
    }

    :local(.content),
    .ihr-layout__content {
      width: 100%;
    }
  }

  @media only screen and (max-width: $breakpoint-tablet - 1) {
    :local(.top),
    .ihr-layout__top {
      padding-left: 24px;
      padding-right: 24px;
    }

    :local(.content),
    .ihr-layout__content {
      width: 100%;
    }
  }

  :local(.header),
  .ihr-layout__header {
    margin-left: auto;
    margin-right: auto;
    max-width: $breakpoint-big-desktop;
    overflow: visible;
    width: 100%;
  }

  :local(.content),
  .ihr-layout__content {
    margin-left: auto;
    margin-right: auto;
  }
}

@mixin simple-layout {
  padding: 0 24px;

  :local(.content),
  .ihr-layout__content {
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    max-width: $breakpoint-big-desktop;
    width: 100%;
  }
}

@mixin fullscreen-layout {
  padding: 0 24px;

  :local(.content),
  .ihr-layout__content {
    width: 100%;
  }
}

:local(.base),
.ihr-layout {
  @include page-top;
  @include base-layout;
}

:local(.profile),
.ihr-layout--profile {
  @include profile-layout
}

:local(.index),
.ihr-layout--index {
  @include index-layout;
}

:local(.simple),
.ihr-layout--simple {
  @include simple-layout
}

// this is to fix forms being not centered
:local(.form),
.ihr-layout--form {
  @include simple-layout;

  :local(.content),
  .ihr-layout__content {
    max-width: 900px;
  }
}

:local(.fullscreen),
.ihr-layout--fullscreen {
  @include fullscreen-layout
}
