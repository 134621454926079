// ------------------------------------------------------------------------------------
// The following variables are the core design variables for the entire design system
//
// When updating this file, ALWAYS update common/sass/variables.ts at the same time.
// ------------------------------------------------------------------------------------

// --------------
// Breakpoints
// --------------
$breakpoint-min:          0;
$breakpoint-tablet:       640px;
$breakpoint-desktop:      1034px;
$breakpoint-big-desktop:  1440px;

// --------------
// Colors
// --------------
// Neutral
$n100: #FFFFFF; /* neutral white */
$n150: #f8fbff; /* 00 */
$n200: #f2f6fd; /* Custom hf 025 grey */
$n250: #eaeff6; /* 05 */
$n300: #d2d9e4; /* 10 */
$n400: #aeb9c9; /* 20 */
$n500: #8d9bae; /* 30 */
$n600: #546378; /* 50 */
$n700: #3c4a5e; /* 60 */
$n800: #273343; /* 70 */

// Blue
$b100: #e1f3ff; /* 05 */
$b200: #cdebfe; /* 10 */
$b300: #8ACFF9; /* 30 */
$b400: #139FF5; /* 50 */
$b500: #0078C2; /* 60 */
$b600: #00588f; /* 70 */

// Cyan
$c100: #EDFCFF;
$c200: #C8F2FA;
$c300: #A2EBFA;
$c400: #61DAF2;
$c500: #4DACBF;
$c600: #32707D;

// Green
$g100: #DBF1DA; /* 05 */
$g200: #CDECCB; /* 10 */
$g300: #84D17F; /* 30 */
$g400: #50B848; /* 50 */
$g500: #317C2C; /* 60 */
$g600: #245A20; /* 70 */

// Indigo
$i100: #f4f6fb; /* 00 */
$i200: #D9E1F2; /* 10 */
$i300: #8E9ED7; /* 30 */
$i400: #3C479D; /* 50 */
$i500: #2E3880; /* 60 */
$i600: #232A63; /* 70 */

// Orange
$o100: #FFF4C7; /* 05 */
$o200: #FEEEA0; /* 10 */
$o300: #FFD540; /* 30 */
$o400: #E59D00; /* 50 */
$o500: #A86D00; /* 60 */
$o600: #754C00; /* 70 */

// Red
$r100: #FDE5E2; /* 05 */
$r200: #FBD5D0; /* 10 */
$r300: #F6947E; /* 30 */
$r400: #F15A29; /* 50 */
$r500: #BD350F; /* 60 */
$r600: #8E280B; /* 70 */

// Yellow
$y100: #FFFDED;
$y200: #FFF7BF;
$y300: #FFEC73;
$y400: #FFE226;
$y500: #BFA91C;
$y600: #7F7012;

// --------------
// Font Sizes
// --------------
$fz-xsmall:               12px;
$fz-small:                14px;
$fz-body:                 16px;
$fz-heading:              18px;
$fz-display:              24px;
$fz-display-large:        36px;

// --------------
// Font Weights
// --------------
$fw-normal:               400;
$fw-semi-bold:            600;
$fw-bold:                 700;

// --------------
// Layout
// --------------
$layout-2xsmall:          16px;
$layout-xsmall:           24px;
$layout-small:            32px;
$layout-medium:           40px;
$layout-large:            64px;
$layout-xlarge:           80px;
$layout-2xlarge:         120px;

// --------------
// Line Heights
// --------------
$lh-xsmall:               18px;
$lh-small:                20px;
$lh-body:                 24px;
$lh-heading:              24px;
$lh-display:              32px;
$lh-display-large:        44px;

// --------------
// Spacing
// --------------
$spacing-3xsmall:          2px;
$spacing-2xsmall:          4px;
$spacing-xsmall:           8px;
$spacing-small:           12px;
$spacing-medium:          16px;
$spacing-large:           24px;
$spacing-xlarge:          32px;
$spacing-2xlarge:         40px;
$spacing-3xlarge:         48px;

// --------------
// Style
// --------------
$border-radius:           4px;

// --------------
// Z Indexes
// --------------
$z-index-absolute-bottom: -9999;
$z-index-default:         0;
$z-index-modal:           1000;
$z-index-date-input:      2000;
$z-index-dropdown-menu:   3000;
$z-index-toaster:         4000;
$z-index-absolute-top:    9999;

// --------------
// Box Shadows
// --------------
$box-shadow-lv1-static:             0 2px 1px rgba(102, 115, 128, 0.2), 0 1px 1px rgba(0, 0, 0, 0.14), 0 1px 3px rgba(0, 0, 0, 0.12);
$box-shadow-lv2-prominent:         0 2px 4px rgba(102, 115, 128, 0.2), 0 4px 5px rgba(0, 0, 0, 0.14), 0 1px 10px rgba(0, 0, 0, 0.12);
$box-shadow-lv3-active:          0 5px 5px rgba(102, 115, 128, 0.18), 0 8px 10px rgba(0, 0, 0, 0.12), 0 3px 14px rgba(0, 0, 0, 0.10);
$box-shadow-lv4-front-of-mind: 0 10px 14px rgba(102, 115, 128, 0.2), 0 22px 35px rgba(0, 0, 0, 0.14), 0 8px 42px rgba(0, 0, 0, 0.12);
