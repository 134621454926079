@import '../../../common/sass/variables';

:local(.radioInput)  {

  $icon-width: 20px;

  input {
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;

    & + .radio {
      width: 100%;
    }


    &:focus + label {
      &.radio {
        border-color: $i400;

        &::before {
          background-color: $n300;
          border-color: $i400;
        }
      }

      &.radio-button {
        background-color: $n200;
        border-color: $i400;
      }
    }

    &:disabled + .radio {
      color: $n500;
      cursor: not-allowed;

      &::before {
        background-color: $n150;
        border-color: $n300;
      }
    }

    &:checked + .radio {
      border-color: $i600;

      &::before {
        border-color: $i400;
      }

      &::after {
        background-image: radial-gradient(circle closest-side, $i400, $i400 50%, transparent 50%, transparent 100%);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        opacity: 1;
        transform: scale(1);
      }

      &:hover {
        border-color: $i400;

        &::before {
          border-color: $i400;
        }
      }
    }

    &:checked + .radio-button {
      background-color: $i100;
      color: $i600;

      &::before {
        background-color: $i100;
      }

      &:hover {
        background-color: $i100;

        &::before {
          background-color: $i100;
        }
      }
    }

    &:disabled + .radio-button {
      background-color: $n150;
      color: $n500;
      cursor: not-allowed;

      &::before {
        background-color: $n150;
        border-color: $n300;
      }
    }
  }

  .radio {
    align-self: stretch;
    color: $n800;
    cursor: pointer;
    font-size: $fz-small;
    line-height: $lh-small;
    margin-left: 0;
    margin-right: 0;
    padding: $spacing-2xsmall $spacing-xlarge $spacing-2xsmall ($icon-width + $spacing-xsmall);
    position: relative;
    transition: .25s ease-out, color .25s ease-out;
    word-break: break-all;

    &::before {
      background-color: $n150;
      border: 2px solid $n400;
      border-radius: 50%;
      content: '';
      display: inline-block;
      height: $icon-width;
      left: 0;
      position: absolute;
      top: 4px;
      transition: .25s ease-out;
      vertical-align: top;
      width: $icon-width;
    }

    &::after {
      content: '';
      display: inline-block;
      height: 16px;
      left: 2px;
      opacity: 0;
      position: absolute;
      top: 6px;
      transform: scale(.8);
      transition: .25s ease-out;
      vertical-align: top;
      width: 16px;
    }

    &.radio-button {
      padding: $spacing-small $spacing-large $spacing-small ($icon-width + $spacing-large);

      &::before {
        left: 12px;
        top: 12px;
      }

      &::after {
        left: 14px;
        top: 14px;
      }
    }

    &:hover {
      &::before {
        background-color: $n300;
        border-color: $n600;
      }
    }
  }

  .radio-button {
    background-color: $n100;
    border: 1px solid $n300;
    border-radius: $border-radius;
    min-width: 240px;

    &:hover {
      background-color: $n200;
    }
  }
}

