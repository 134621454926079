@import '../../variables.scss';

$pill-styles: (
  primary: (
    background-color: $i100,
    text-color: $i600,
    border-color: $i400
  ),
  secondary: (
    background-color: $b100,
    text-color: $b600,
    border-color: $b400
  ),
  success: (
    background-color: $g100,
    text-color: $g600,
    border-color: $g400
  ),
  warning: (
    background-color: $o100,
    text-color: $o600,
    border-color: $o400
  ),
  alert: (
    background-color: $r100,
    text-color: $r600,
    border-color: $r300
  ),
  neutral: (
    background-color: $n250,
    text-color: $n800,
    border-color: $n400
  ),
  highlight: (
    background-color: $c100,
    text-color: $c600,
    border-color: $c400
  ),
  dark: (
    background-color: $n700,
    text-color: $n200,
    border-color: $n100
  )
);

@mixin pill-color(
  $text-color,
  $background-color,
  $border-color
) {
  background: $background-color;
  border: 1px solid $border-color;
  color: $text-color;
}

// --------------
// Legacy global pill (label) class for non-javascript support
// --------------
// sass-lint:disable placeholder-in-extend
.label {
  border-radius: 32px;
  display: inline-block;
  font-size: $fz-xsmall;
  font-weight: $fw-semi-bold;
  line-height: 14px;
  padding: 4px 8px;
  white-space: nowrap;

  @each $color, $value in $pill-styles {
    &.#{$color} {
      @include pill-color(
          map-get($value, text-color),
          map-get($value, background-color),
          map-get($value, border-color)
      );
    }
  }

  &.medium {
    font-size: $fz-small;
    line-height: 16px;
  }

  &.large {
    font-size: $fz-body;
    line-height: 18px;
    padding: 4px 12px;
  }

  // Neutral by default
  @extend .neutral;

  // Domain specific colours; unfortunately have to be kept for now
  &.leave {
    @extend .warning;
  }

  &.overdue {
    @extend .alert;
  }

  &.draft {
    @extend .neutral;
  }

  &.abandoned {
    @extend .primary;
  }
}
// sass-lint:enable placeholder-in-extend


