@import '../../../../common/sass/variables';

:local(.ListClickableColumns) {
  &:focus,
  & *:focus {
    outline: 0;
  }

  &:hover {
    :not(.label) {
      &.cell {
        &:first-child {
          color: $i400;
        }
      }

      &.cell-label {
        color: $i400;
      }
    }

    .label {
      cursor: pointer;
    }
  }

  &,
  .modal-button,
  .modal-trigger {
    align-items: center;
    cursor: pointer;
    display: flex;
    width: 100%;
  }
}
