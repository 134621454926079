@charset "UTF-8";
/* neutral white */
/* 00 */
/* Custom hf 025 grey */
/* 05 */
/* 10 */
/* 20 */
/* 30 */
/* 50 */
/* 60 */
/* 70 */
/* 05 */
/* 10 */
/* 30 */
/* 50 */
/* 60 */
/* 70 */
/* 05 */
/* 10 */
/* 30 */
/* 50 */
/* 60 */
/* 70 */
/* 00 */
/* 10 */
/* 30 */
/* 50 */
/* 60 */
/* 70 */
/* 05 */
/* 10 */
/* 30 */
/* 50 */
/* 60 */
/* 70 */
/* 05 */
/* 10 */
/* 30 */
/* 50 */
/* 60 */
/* 70 */
:local(.DataTable) { border: 0; display: flex; flex-direction: column; overflow: auto; position: relative; }

:local(.DataTable) .rt-table { align-items: stretch; border-collapse: collapse; display: flex; flex: auto 1; flex-direction: column; width: 100%; }

:local(.DataTable) .rt-thead { background: #eaeff6; display: flex; flex: 1 0 auto; flex-direction: column; font-weight: 600; user-select: none; }

:local(.DataTable) .rt-thead.-filters { border-bottom: 1px solid #d2d9e4; }

:local(.DataTable) .rt-thead.-filters .rt-th input { margin-bottom: 0; }

:local(.DataTable) .rt-thead.-headerGroups { background: #f2f6fd; border-bottom: 2px solid #d2d9e4; }

:local(.DataTable) .rt-thead .rt-th, :local(.DataTable) .rt-thead .rt-td { box-shadow: inset 0 0 0 0 transparent; line-height: normal; padding: .75rem; transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275); }

:local(.DataTable) .rt-thead .rt-th.-cursor-pointer, :local(.DataTable) .rt-thead .rt-td.-cursor-pointer { cursor: pointer; }

:local(.DataTable) .rt-thead .rt-th { line-height: 1.5; outline: none; text-align: left; }

:local(.DataTable) .rt-thead .rt-th.content-right { text-align: right; }

:local(.DataTable) .rt-thead .rt-th.content-center { text-align: center; }

:local(.DataTable) .rt-thead .rt-th.-cursor-pointer { cursor: pointer; }

:local(.DataTable) .rt-thead .rt-th.-cursor-pointer::before { color: #273343; content: ""; font-family: 'Font Awesome 5 Pro'; font-size: 17px; font-weight: bold; margin-right: 10px; position: relative; }

:local(.DataTable) .rt-thead .rt-th.-cursor-pointer.-sort-asc::before { color: #3C479D; content: ""; font-family: 'Font Awesome 5 Pro'; font-size: 17px; font-weight: bold; margin-right: 10px; position: relative; }

:local(.DataTable) .rt-thead .rt-th.-cursor-pointer.-sort-desc::before { color: #3C479D; content: ""; font-family: 'Font Awesome 5 Pro'; font-size: 17px; font-weight: bold; margin-right: 10px; position: relative; }

:local(.DataTable) .rt-thead .rt-th.-cursor-pointer div { display: inline; }

:local(.DataTable) .rt-thead .rt-resizable-header { overflow: visible; }

:local(.DataTable) .rt-thead .rt-resizable-header:last-child { overflow: hidden; }

:local(.DataTable) .rt-thead .rt-resizable-header-content { overflow: hidden; text-overflow: ellipsis; }

:local(.DataTable) .rt-thead .rt-header-pivot { border-right-color: #d2d9e4; }

:local(.DataTable) .rt-thead .rt-header-pivot::after { border: 8px rgba(255, 255, 255, 0); border-left-color: #FFFFFF; margin-top: -8px; }

:local(.DataTable) .rt-thead .rt-header-pivot::before { border: 10px rgba(102, 102, 102, 0); border-left-color: #d2d9e4; margin-top: -10px; }

:local(.DataTable) .rt-thead .rt-header-pivot::after, :local(.DataTable) .rt-thead .rt-header-pivot::before { border: solid transparent; content: ' '; height: 0; left: 100%; pointer-events: none; position: absolute; top: 50%; width: 0; }

:local(.DataTable).show-vertical-lines .rt-th, :local(.DataTable).show-vertical-lines .rt-td { border-right: solid 1px #d2d9e4; }

:local(.DataTable).show-vertical-lines .rt-th:last-child, :local(.DataTable).show-vertical-lines .rt-td:last-child { border-right: 0; }

:local(.DataTable) .rt-tbody { display: flex; flex: 99999 1 auto; flex-direction: column; }

:local(.DataTable) .rt-tbody .rt-tr-group { align-items: stretch; border-bottom: solid 1px #eaeff6; display: flex; flex: 1 0 auto; flex-direction: column; }

:local(.DataTable) .rt-tbody .rt-tr-group:last-child { border-bottom: 0; }

:local(.DataTable) .rt-tbody .rt-expandable { cursor: pointer; text-overflow: clip; }

:local(.DataTable) .rt-tr { display: inline-flex; flex: 1 0 auto; }

:local(.DataTable) .rt-td { align-items: center; display: flex; flex-direction: row; padding: .75rem; }

:local(.DataTable) .rt-td.content-right { flex-direction: row-reverse; }

:local(.DataTable) .rt-td.content-center { flex-direction: column; }

:local(.DataTable) .rt-th, :local(.DataTable) .rt-td { flex: 1 0 0; text-overflow: ellipsis; transition: .3s ease; transition-property: width, min-width, padding, opacity; }

:local(.DataTable) .rt-th.-hidden, :local(.DataTable) .rt-td.-hidden { border: 0; min-width: 0; opacity: 0; padding: 0; width: 0; }

:local(.DataTable) .rt-expander { color: transparent; display: inline-block; margin: 0 10px; position: relative; }

:local(.DataTable) .rt-expander::after { border-left: 5.04px solid transparent; border-right: 5.04px solid transparent; border-top: 7px solid #d2d9e4; content: ''; cursor: pointer; height: 0; left: 50%; position: absolute; top: 50%; transform: translate(-50%, -50%) rotate(-90deg); transition: width, height 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275); width: 0; }

:local(.DataTable) .rt-expander.-open ::after { transform: translate(-50%, -50%) rotate(0); }

:local(.DataTable) .rt-resizer { bottom: 0; cursor: col-resize; display: inline-block; position: absolute; right: -18px; top: 0; width: 36px; z-index: 10; }

:local(.DataTable) .rt-tfoot { box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15); display: flex; flex: 1 0 auto; flex-direction: column; }

:local(.DataTable) .page-details { color: #546378; font-size: 14px; }

:local(.DataTable) .pagination-top .-pagination { margin-bottom: 10px; }

:local(.DataTable) .pagination-top .-pagination .page-details { display: none; }

:local(.DataTable) .pagination-bottom .-pagination { border-top: solid 1px #d2d9e4; padding-top: 16px; }

:local(.DataTable) .pagination-bottom .-pagination .search-filter, :local(.DataTable) .pagination-bottom .-pagination .page-size-options { display: none; }

:local(.DataTable) .-pagination { align-items: center; display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; z-index: 1; }

:local(.DataTable) .-pagination .search-filter { display: flex; margin-right: 16px; }

:local(.DataTable) .-pagination .search-filter input { width: auto; }

:local(.DataTable) .-pagination .page-size-options { align-self: center; flex: 3 0 auto; }

:local(.DataTable) .-pagination .page-size-options select { appearance: auto; border: 0; border-bottom: 1px dashed #546378; border-radius: 0; display: inline-block; height: auto; line-height: 16px; margin: 0; padding: 0; width: auto; }

:local(.DataTable) .-pagination .page-navigation { align-items: center; display: flex; height: 100%; }

:local(.DataTable) .-pagination .ellipsis { color: #273343; cursor: not-allowed; padding: .1875rem .5rem; }

:local(.DataTable) .-pagination .-previous, :local(.DataTable) .-pagination .-next { flex: 1; }

:local(.DataTable) .-pagination .-center { align-items: center; display: flex; flex: 1.5; flex-direction: row; flex-wrap: wrap; justify-content: space-around; margin-bottom: 0; text-align: center; }

:local(.DataTable) .-pagination .-btn { background: transparent; border: 0; border-radius: 13px; color: #273343; cursor: pointer; display: block; font-size: .875rem; height: 26px; margin-left: 4px; margin-right: 4px; min-width: 26px; outline: none; padding: 0 8px; transition: color, background-color .1s ease; }

:local(.DataTable) .-pagination .-btn:not([disabled]):hover, :local(.DataTable) .-pagination .-btn:not([disabled]):focus { background-color: #d2d9e4; }

:local(.DataTable) .-pagination .-btn[disabled] { background-color: transparent; color: #aeb9c9; cursor: not-allowed; }

:local(.DataTable) .-pagination .-btn.current { background-color: #3C479D; color: #FFFFFF; }

:local(.DataTable) .-pagination .-btn.current:hover, :local(.DataTable) .-pagination .-btn.current:focus { background-color: #3C479D; color: #FFFFFF; cursor: default; }

:local(.DataTable) .rt-resizing .rt-th, :local(.DataTable) .rt-resizing .rt-td { cursor: col-resize; transition: none; user-select: none; }

:local(.DataTable) .-loading { background: rgba(255, 255, 255, 0.85); bottom: 0; display: block; left: 0; opacity: 0; pointer-events: none; position: absolute; right: 0; top: 0; transition: opacity .3s ease; z-index: -1; }

:local(.DataTable) .-loading > div { color: #3c4a5e; display: block; font-size: 14px; left: 0; position: absolute; text-align: center; top: 50%; width: 100%; }

:local(.DataTable) .-loading.-active { opacity: 1; pointer-events: all; z-index: 2; }

:local(.DataTable) .loading-title { color: #3C479D; }
