@import '../../../common/sass/variables';

:local(.checkboxInput) {

  $icon-width: 20px;

  input {
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;

    &:focus + label {
      &.checkbox {
        border-color: $i400;

        &::before {
          background-color: $n300;
          border-color: $i400;
        }
      }

      &.checkbox-button {
        background-color: $n200;
        border-color: $i400;
      }
    }

    &:checked + .checkbox,
    &:indeterminate + .checkbox {
      border-color: $i600;

      &::before {
        border-color: $i400;
      }

      &::after {
        opacity: 1;
      }

      &:hover {
        border-color: $i400;

        &::before {
          border-color: $i400;
        }
      }
    }

    &:checked + .checkbox-button {
      background-color: $i100;
      color: $i600;

      &:hover {
        background-color: $i100;

        &::before {
          background-color: $i100;
        }
      }
    }

    &:disabled + .checkbox-button {
      background-color: $n150;
      color: $n500;
      cursor: not-allowed;

      &::before {
        background-color: $n150;
        border-color: $n300;
      }

      &:hover {
        background-color: $n150;
        border-color: $n300;

        &::before {
          background-color: $n150;
          border-color: $n300;
        }
      }
    }

    &:disabled + .checkbox {
      border-color: $n300;
      color: $n500;
      cursor: not-allowed;

      &::before {
        background-color: $n150;
        border-color: $n300;
      }

      &::after {
        background: $n500;
        box-shadow:
        2px 0 0 $n500,
        4px 0 0 $n500,
        4px -2px 0 $n500,
        4px -4px 0 $n500,
        4px -6px 0 $n500,
        4px -8px 0 $n500;
      }

      &:hover {
        border-color: $n300;

        &::before {
          border-color: $n300;
        }
      }
    }

    &:indeterminate {
      + .checkbox,
      &:disabled + .checkbox {
        &::after {
          box-shadow: 0 0 0 $n500;
          transform: rotate(0);
          width: 12px;
        }
      }
    }
  }

  .checkbox {
    align-self: stretch;
    color: $n800;
    cursor: pointer;
    font-size: $fz-small;
    line-height: $lh-small;
    margin-left: 0;
    margin-right: 0;
    min-height: calc(#{$spacing-2xsmall} * 2 + #{$icon-width});
    padding: $spacing-2xsmall $spacing-medium $spacing-2xsmall $spacing-xlarge;
    position: relative;
    transition: .25s ease-out, color .25s ease-out;

    &::before {
      background-color: $n150;
      border: 2px solid $n400;
      border-radius: $border-radius;
      content: '';
      display: inline-block;
      height: $icon-width;
      left: 0;
      position: absolute;
      top: 4px;
      transition: .25s ease-out, color .25s ease-out;
      vertical-align: top;
      width: $icon-width;
    }

    &::after {
      background: $i400;
      border-radius: $border-radius;
      box-shadow:
      2px 0 0 $i400,
      4px 0 0 $i400,
      4px -2px 0 $i400,
      4px -4px 0 $i400,
      4px -6px 0 $i400,
      4px -8px 0 $i400;
      content: '';
      height: 3px;
      left: 4px;
      opacity: 0;
      position: absolute;
      top: 13px;
      transform: rotate(45deg);
      transition: .25s ease-out;
      width: 3px;
    }

    &.checkbox-button {
      padding: $spacing-small $spacing-large $spacing-small ($icon-width + $spacing-large);

      &::before {
        left: 12px;
        top: 12px;
      }

      &::after {
        left: 16px;
        top: 21px;
      }
    }

    &:hover {

      &::before {
        background-color: $n300;
        border-color: $n600;
      }
    }
  }

  .checkbox-button {
    background-color: $n100;
    border: 1px solid $n300;
    border-radius: $border-radius;
    min-width: 240px;
    width: 100%;

    &:hover {
      background-color: $n200;
    }
  }
}
